/*
 * This module is used to display a Hubspot form only once on a page.
 * If a page contains multiple Hubspot forms, only the first one will be displayed.
 * The other forms will be displayed when the user scrolls to the corresponding placeholder.
*/
export default class UniqueHubspotForms {

	constructor(c) {

		let formContainer = document.getElementById('hubSpotContactFormContainer');
		let form = document.getElementById('hubSpotContactForm');
		let placeholders = document.querySelectorAll('.hubSpotFormPlaceholder');

		let observer = new IntersectionObserver(function (entries, observer) {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					// Ein Platzhalter wird sichtbar
					entry.target.appendChild(form);
					form.style.display = 'block';

					// Ersetzen des ursprünglichen Containers durch einen neuen Platzhalter, falls nötig
					if (formContainer && !formContainer.contains(form)) {
						formContainer.classList.add('hubSpotFormPlaceholder');
						formContainer = null; // Verhindert, dass dieser Block erneut ausgeführt wird
					}

					// Beenden der Beobachtung aller Platzhalter
					// observer.disconnect();
				}
			});
		}, {
			threshold: [0.1]
		});

		// Beobachten aller Platzhalter und des ursprünglichen Containers
		[...placeholders, formContainer].forEach(element => {
			if(element) observer.observe(element);
		});

	}

}